
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "storefront": {
    "name": "Diamido"
  },
  "nuxt": {
    "buildId": "36855d63-6ed9-472b-9bd0-f82f003c46e6"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/local/app/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
